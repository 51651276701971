<template>
  <div class="w center_all basic-configuration">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>微信传播</li>
        <li>-></li>
        <li>本刊设置</li>
        <li>-></li>
        <li>基础配置</li>
      </ul>
      <el-tabs v-model="activeName">
        <el-tab-pane label="微网刊主页配置" name="first"><homePage ref="homePage" :ruleForm="ruleForm"></homePage></el-tab-pane>
        <el-tab-pane label="往期目录配置" name="second"><previousCatalog ref="previousCatalog" :ruleForm="ruleForm"></previousCatalog></el-tab-pane>
        <el-tab-pane label="专题目录配置" name="third"><topicCatalog ref="topicCatalog" :ruleForm="ruleForm"></topicCatalog></el-tab-pane>
        <el-tab-pane label="其他配置" name="fourth"><others ref="others" :ruleForm="ruleForm"></others></el-tab-pane>
      </el-tabs>
      <div class="btns" style="text-align: center">
        <el-button @click="handleSubmit">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { GetConfig, UpdateConfig } from '@/api/weChatPush'
import homePage from './components/homePage'
import previousCatalog from './components/previousCatalog'
import topicCatalog from './components/topicCatalog'
import others from './components/others'
import { mapGetters } from 'vuex'
export default {
  name: 'basicConfiguration',
  components: { homePage, previousCatalog, topicCatalog, others },
  computed: {
    ...mapGetters({
      platMagId: 'platMagId',
      platTenantId: 'platTenantId',
      magId: 'magId'
    })
  },
  watch: {
    magId() {
      this.initData()
    }
  },
  data() {
    return {
      ruleForm: {},
      rules: {},
      activeName: 'first'
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.ruleForm = {
        id: '',
        // 微网刊主页配置---开始
        color: '', // 配色
        weChatColor: '', // 详情页配色
        subscribeSwitch: 0, //是否弹出关注提醒
        accessCount: '', //阅读第x篇文章引导关注
        logoCoverId: '', // 微网刊logo图
        logoName: '', // 微网刊logo名称
        logoNameEn: '', // 微网刊logo英文名称
        logoHref: '', // 微网刊logo链接
        subjectCoverId: '', // 顶部介绍封面图
        subjectTitle: '', // 顶部介绍标题
        subjectInfo: '', // 顶部介绍内容
        magCoverId: '', // 期刊介绍封面图
        magTitle: '', // 期刊介绍标题
        magHref: '', // 期刊封面URL
        magHrefInfo: '', // 封面故事介绍
        magInfo: '', // 期刊介绍内容
        wechatCoverId: '', // 左侧二维码图
        wechatCoverName: '', // 左侧二维码图名称
        qrCodeRightId: '', // 右侧二维码图
        qrCodeRightName: '', // 右侧二维码图名称
        qrCodeBackgroundId: '', // 二维码背景图
        wechatCoverId3: '', // 第三个二维码图
        wechatCoverName3: '', // 第三个二维码图名称
        wechatCoverId4: '', // 第四个二维码图
        wechatCoverName4: '', // 第四个二维码图名称
        magDefaultCoverId: '', // 论文默认封面图
        // 微网刊主页配置---结束
        // 往期目录配置---开始
        pastTemplate: '', // 往期目录模板
        pastMoreCoverId: '', // 查看更多背景图
        bannerId: '', // 更多页面banner图
        pastMoreColor: '', // 查看更多背景色
        isBigLogo: 0, // 是大logo
        // 往期目录配置---结束
        // 专题目录配置---开始
        specialTemplate: '', // 专题目录模板
        specialMoreCoverId: '', // 查看更多背景图
        autoUpdate:0,// 发布后内容自动更新
        // 专题目录配置---结束
        // 其它配置---开始
        copyright: '本系统由北京北大方正电子有限公司提供技术支持', // 版权信息
        htmlOrder: 'pubUrl,htmlUrl,abstractUrl,wechatUrl,url', // 文章地址类型优先级 htmlUrl:全文 abstractUrl:摘要 wechatUrl:微信 url :知网
        weChatShareCoverId: '', // 微信分享图片
        weChatShareTitle: '', // 微信分享标题
        weChatShareContent: '' // 微信分享内容
        // 其它配置---结束
      }
      this.getConfig()
    },
    // 获取当前配置
    getConfig() {
      this.$nextTick(() => {
        // this.$refs.homePage.$refs.commonType.$refs.ruleForm.resetFields()
        // this.$refs.previousCatalog.$refs.ruleForm.resetFields()
        // this.$refs.topicCatalog.$refs.ruleForm.resetFields()
        // this.$refs.others.$refs.ruleForm.resetFields()
        let params = {
          tenantId: this.platTenantId,
          publicationId: this.platMagId,
          magId: this.magId
        }
        GetConfig(params).then((resp) => {
          let { data, status } = resp.data
          if (status === 0) {
            for (var item in data) {
              if (this.ruleForm.hasOwnProperty(item)) {
                this.ruleForm[item] = data[item] || this.ruleForm[item]
              }
            }
          }
        })
      })
    },
    // 提交修改
    handleSubmit() {
      this.ruleForm = { magId: this.magId, ...this.ruleForm }
      UpdateConfig(this.ruleForm).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success('修改成功')
          this.getConfig()
        }
      })
    }
  }
}
</script>
<style lang="scss">
.basic-configuration {
  .betterSize {
    margin-left: 30px;
  }
  .part {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .left-part {
      width: 35%;
      img {
        width: 100%;
      }
    }
    .right-part {
      width: 60%;
      border: 1px dashed #bbbbbb;
      padding: 20px 20px 0 20px;
      .flex-content {
        display: flex;
        .left {
          width: 45%;
        }
        .right {
          width: 55%;
        }
      }
    }
    .upload-img {
      position: relative;
      border: 1px solid #ddd;
      background: #f4f4f4;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .upload-demo {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 156px;
      height: 231px;
      .upload-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    .el-upload__tip {
      color: #999;
      font-size: 14px;
      margin: 0 0 20px 110px;
    }
    .el-tabs--border-card > .el-tabs__content {
      padding: 0;
    }
  }
}
</style>
<style scoped lang="scss">
.basic-configuration {
  text-align: left;
}
</style>
