<template>
  <div class="w center_all web-journal-template">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>微信传播</li>
        <li>-></li>
        <li>通用组件模板</li>
      </ul>
      <el-tabs v-model="activeName">
        <el-tab-pane label="微网刊主页模板" name="first">
          <homePage ref="homePage"></homePage>
        </el-tab-pane>
        <el-tab-pane label="微网刊目录模板" name="second">
          <catalog ref="catalog"></catalog>
        </el-tab-pane>
        <el-tab-pane label="图文消息模板" name="three">
          <pictureText ref="pictureText"></pictureText>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import homePage from './components/allTypeTemplate/homePage'
import catalog from './components/allTypeTemplate/catalog'
import pictureText from './components/allTypeTemplate/pictureText'
export default {
  name: 'webJournalTemplate',
  components: {
    homePage,
    catalog,
    pictureText
  },
  computed: {},
  data() {
    return {
      activeName: 'first'
    }
  },
  mounted() {
    if (this.$route.params.activeName) {
      this.activeName = this.$route.params.activeName
    }
  },
  methods: {}
}
</script>
<style lang="scss">
.template-cover-box {
  .el-upload {
    width: 100%;
    height: 100%;
  }
}
</style>
<style lang="scss">
.web-journal-template {
  text-align: left;
  background-color: #fff;
  .el-card-item {
    padding: 0;
    margin-bottom: 10px;
    .template-cover-box {
      position: relative;
      overflow: hidden;
      .template-cover {
        width: 100%;
        max-width: 235px;
        height: 188px;
        display: block;
        position: relative;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .upload-demo {
        width: 100%;
        height: 188px;
        position: absolute;
        top: 0;
        opacity: 0;
      }
    }
    .template-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
    }
  }
  .card-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    height: 17px;
    .el-button {
      width: 38px;
      padding: 3px;
      margin-left: 3px;
      margin-top: 5px;
      text-indent: unset;
    }
  }
}
</style>
