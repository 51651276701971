<template>
  <div>
    <el-row class="row-item">
      <el-col :span="12">
        <div class="img-box">
          <img :src="item.imgSrc" alt="" />
        </div>
      </el-col>
      <el-col :span="12" class="edit-box">
        <div class="edit-item">
          显示：
          <el-radio-group v-model="item.isShow">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <div class="edit-item">
          摘要：
          <el-radio-group v-model="item.abstractIsShow" :disabled="!item.isShow">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <div class="edit-item">
          显示：
          <el-select v-model="item.showSize" placeholder="请选择" style="width: 100px" :disabled="!item.isShow">
            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          条
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'modlue2',
  props: {
    item: {
      type: Object
    }
  },
  watch: {
    'item.isShow'(value) {
      if (value) {
        this.item.isMerge = false
        this.item.abstractIsShow = true
        this.item.showSize = 2
      } else {
        this.item.isMerge = false
        this.item.abstractIsShow = false
        this.item.showSize = ''
      }
    }
  },
  data() {
    return {
      options3: [
        { label: '2', value: 2 },
        { label: '4', value: 4 },
        { label: '6', value: 6 }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.row-item {
  border: 1px dashed #cccccc;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
}
.img-box {
  img {
    width: 100%;
  }
}
.edit-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.edit-item {
  text-align: left;
  padding-left: 40px;
  margin-bottom: 10px;
}
</style>
