<!-- homePage -->
<template>
  <div class="homePage">
    <commonType ref="commonType" :ruleForm="ruleForm"></commonType>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import commonType from '../../components/template/commonType'
export default {
  name: 'homePage',
  data() {
    return {
      options: []
    }
  },
  magId() {},
  watch: {
    // 'ruleForm.type'(n) { // 自科版字数限制为6
    //   if (this.ruleForm.type === 1 && this.ruleForm.magTitle.length > 6) {
    //     this.ruleForm.magTitle = this.ruleForm.magTitle.substring(0, 6)
    //   }
    // }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    commonType
  },
  computed: {
    ...mapGetters({
      platMagId: 'platMagId',
      platTenantId: 'platTenantId',
      magId: 'magId'
    })
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.homePage {
  padding: 20px 0;
}
</style>
