<template>
  <div>
    <el-row class="row-item">
      <el-col :span="12">
        <div class="img-box">
          <img :src="item.imgSrc" alt="" />
        </div>
      </el-col>
      <el-col :span="12" class="edit-box">
        <div class="edit-item">
          显示：
          <el-radio-group v-model="item.isShow" :disabled="item.disabled">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <div class="edit-item" v-if="showAbstract">
          摘要：
          <el-radio-group v-model="item.abstractIsShow" :disabled="!item.isShow">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <div class="edit-item" v-if="showColumn">
          是否显示栏目：
          <el-radio-group v-model="item.columnIsShow">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <div class="edit-item">
          显示:
          <el-select v-model="item.showNumber" placeholder="请选择" style="width: 100px" :disabled="!item.isShow">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          次
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'modlue4',
  props: {
    item: {
      type: Object
    },
    showAbstract: {
      type: Boolean,
      default: true
    },
    showColumn: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'item.isShow'(value) {
      if (!value) {
        this.item.isMerge = false
        this.item.abstractIsShow = false
        this.item.showNumber = ''
      }
    }
  },
  data() {
    return {
      options2: [
        { label: '-1', value: -1 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.row-item {
  border: 1px dashed #cccccc;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
}
.img-box {
  img {
    width: 100%;
  }
}
.edit-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.edit-item {
  text-align: left;
  padding-left: 40px;
  margin-bottom: 10px;
}
</style>
