<template>
  <div class="web-journal-template">
    <el-row type="flex" justify="space-between">
      <el-col :span="4">
        <el-button type="primary" @click="createNewTemplate">新建模版</el-button>
      </el-col>
      <el-col :span="6" style="text-align: right">
        模版类别：
        <el-select v-model="currentTemplateType" placeholder="请选择" @change="templateTypeChange">
          <el-option v-for="item in templateTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row v-loading="loading" :gutter="10" style="margin-top: 15px">
      <el-col v-for="(item, index) in templateData" :key="item.id" style="max-width: 230px">
        <el-card :body-style="{ padding: '7px' }" class="el-card-item">
          <div class="template-cover-box" @click="templateClick(item, index)">
            <div class="template-cover">
              <img class="cus-img" :class="item.coverId ? '' : 'defaultImg'" :src="item.templateCover" alt />
            </div>
            <el-upload :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" class="upload-demo" :auto-upload="true" :on-success="handleTemplateCoverSuccess">
              <el-button class="postion2" size="small" type="primary">上传图片</el-button>
            </el-upload>
          </div>
          <div style="padding: 5px">
            <p class="template-name">{{ item.name }}</p>
            <div class="card-bottom">
              <span class="template-type">
                <el-tag size="mini" effect="plain" :type="templateTagType(item.type)">{{ formatterTemplateType(item.type) }}</el-tag>
              </span>
              <p class="button-area">
                <el-button type="text" @click="preEditTem(item)">编辑</el-button>
                <el-button type="text" @click="preDeleteTem(item.id)">删除</el-button>
              </p>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <Pagination v-if="templateData.length > 0" :initObj="initObj" @onChangePageData="handleChangePageData" />
    <!-- 删除模版提示 -->
    <el-dialog title="提示" :visible.sync="deleteTemplateVisible" width="30%">
      <span>确定删除该模版吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteTemplateVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteTemplateSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { GetJournalInfo, GetTemplateList } from '@/api/selfPush'
import { GetPictureStream, UploadPictureStream, GetPicturePath, getTemplate, submitWorktemplate, DeleteConfig } from '@/api/configManage'
import { UpdateTemplateCover } from '@/api/weChatPush'
export default {
  name: 'webJournalTemplate',
  components: {
    Pagination
  },
  computed: {},
  data() {
    return {
      loading: true,
      morImg: require('@/assets/img/img_default.png'),
      defaultImg: require('@/assets/img/default.png'),
      templateTypeOptions: [
        { label: '全部', value: '' },
        { label: '遥感学报样式', value: 1 },
        { label: '中山大学社科版样式', value: 2 },
        { label: '中华口腔医学样式', value: 3 },
        { label: '含能材料样式', value: 4 }
      ],
      templateData: [],
      UploadPictureStream: UploadPictureStream,
      templateCoverId: '',
      templateCoverSrc: '',
      deleteTemplateVisible: false,
      currentTemplateId: '',
      currentTemplateType: '',
      GetPictureStream: GetPictureStream,
      GetPicturePath: GetPicturePath,
      initObj: {
        page: 1,
        size: 10
      },
      currentPage: 1,
      clickTemplateItem: '',
      clickTemplateIndex: ''
    }
  },
  mounted() {
    this.getTemplateData()
  },
  methods: {
    formatterTemplateType(value) {
      let str = ''
      switch (value) {
        case 1:
          str = '遥感'
          break
        case 2:
          str = '社科'
          break
        case 3:
          str = '医学'
          break
        case 4:
          str = '含能材料'
          break
      }
      return str
    },
    templateTagType(value) {
      let str = ''
      switch (value) {
        case 1:
          str = ''
          break
        case 2:
          str = 'success'
          break
        case 3:
          str = 'warning'
          break
        case 4:
          str = 'danger'
          break
      }
      return str
    },
    // 获取模版列表
    getTemplateData() {
      this.loading = true
      let params = {
        page: this.initObj.page - 1,
        size: this.initObj.size,
        type: this.currentTemplateType,
        templateType: 0
      }
      GetTemplateList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.templateData = data.content
          this.templateData.map((item, index) => {
            if (item.coverId) {
              this.$set(this.templateData[index], 'templateCover', `${GetPictureStream}?pictureId=${item.coverId}`)
            } else {
              this.$set(this.templateData[index], 'templateCover', this.morImg)
            }
          })
          this.initObj = {
            page: this.currentPage,
            size: data.size,
            total: data.totalElements
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
        this.loading = false
      })
    },
    templateClick(item, index) {
      this.clickTemplateItem = item
      this.clickTemplateIndex = index
    },
    //模板封面上传成功后
    handleTemplateCoverSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.$set(this.templateData[this.clickTemplateIndex], 'templateCover', URL.createObjectURL(file.raw))
      const params = {
        coverId: ID,
        id: this.clickTemplateItem.id
      }
      UpdateTemplateCover(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success('上传成功')
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
        // this.getTemplateData();
      })
    },
    // 筛选模版类别
    templateTypeChange() {
      this.initObj.page = 1
      this.getTemplateData()
    },
    // 新建模版
    createNewTemplate() {
      this.$router.push({
        name: 'handleTemplate',
        params: {
          templateState: 'create'
        }
      })
    },
    // 编辑模版
    preEditTem(value) {
      this.$router.push({
        name: 'handleTemplate',
        params: {
          templateState: 'edit',
          templateId: value.id
        }
      })
    },
    // 删除模版
    preDeleteTem(value) {
      this.currentTemplateId = value
      this.deleteTemplateVisible = true
    },
    //确定删除
    deleteTemplateSure() {
      const params = {
        ids: this.currentTemplateId
      }
      DeleteConfig(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success(res.data.message)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
        // 刷新列表
        this.getTemplateData()
        this.deleteTemplateVisible = false
      })
    },
    // 页码改变了之后的操作
    handleChangePageData(value) {
      this.initObj.page = value.page
      this.initObj.size = value.size
      this.currentPage = value.page
      this.getTemplateData()
    }
  }
}
</script>
<style lang="scss">
.template-cover-box {
  .el-upload {
    width: 100%;
    height: 100%;
  }
}
</style>
<style lang="scss" scoped>
.web-journal-template {
  text-align: left;
  background-color: #fff;
  .image-icon {
    text-align: center;
    line-height: 200px;
    display: block;
    font-size: 14px;
    height: 204px;
    border: 1px solid rgb(221, 221, 221);
  }
  .el-card-item {
    padding: 0;
    margin-bottom: 10px;
    .template-cover-box {
      position: relative;
      overflow: hidden;
      .template-cover {
        width: 100%;
        max-width: 235px;
        height: 188px;
        display: block;
        position: relative;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .upload-demo {
        width: 100%;
        height: 188px;
        position: absolute;
        top: 0;
        opacity: 0;
      }
    }
    .template-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
    }
  }
  .card-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    height: 17px;
    .el-button {
      width: 38px;
      padding: 3px;
      margin-left: 3px;
      margin-top: 5px;
      text-indent: unset;
    }
  }
  .el-dialog__wrapper {
    background: rgba(0, 0, 0, 0.3) !important;
    padding: 0;
  }

  .mag-cover-box {
    position: relative;

    overflow: hidden;
    .mag-cover {
      height: 231px;
      width: 156px;
      border: 1px solid #ddd;
      background: #f4f4f4;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      img {
        height: auto;
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .upload-demo {
      width: 156px;
      height: 231px;
      position: absolute;
      top: 0;
    }
    .postion {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    .tip {
      color: #999;
      font-size: 14px;
      margin-top: 10px;
      text-align: left;
      line-height: 24px;
    }
  }
  .cus-page {
    .el-pagination {
      display: flex;
    }
  }
}
</style>
