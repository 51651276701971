<!-- defalutCover -->
<template>
  <div class="defalutCover">
    <el-form ref="ruleForm" :model="ruleForm" label-width="110px">
      <!-- 论文默认封面设置 -->
      <div class="part defalut-cover">
        <div class="left-part">
          <img :src="defaultCoverImg" alt="" />
        </div>
        <div class="right-part">
          <el-form-item label="每期论文封面：" size="zdy4" prop="magDefaultCoverId ">
            <div class="wechat-cover upload-img">
              <img :src="magDefaultCoverSrc" alt />
              <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleMagDefaultCover">
                <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
              </el-upload>
            </div>
            <el-button type="small" @click="resetImg" style="margin: 0px 20px 0 0">重置</el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream } from '@/api/configManage'
export default {
  name: 'defalutCover',
  data() {
    return {
      morImg: require('@/assets/img/img_default.png'),
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      magDefaultCover: '',
      defaultCoverImg: require('@/assets/img/default-cover.jpg')
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {
    //论文默认封面
    magDefaultCoverSrc() {
      return this.magDefaultCover ? this.magDefaultCover : this.ruleForm.magDefaultCoverId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.magDefaultCoverId}` : this.morImg
    }
  },
  created() {},
  methods: {
    // 成功上传论文默认封面
    handleMagDefaultCover(res, file) {
      const { data } = res
      const { ID } = data
      this.magDefaultCover = URL.createObjectURL(file.raw)
      this.ruleForm.magDefaultCoverId = ID
    },
    resetImg() {
      this.magDefaultCover = ''
      this.ruleForm.magDefaultCoverId = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.defalutCover {
  .left-part {
    height: 196px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .wechat-cover {
    width: 150px;
    height: 150px;
    .upload-demo {
      width: 150px !important;
      height: 150px !important;
    }
  }
}
</style>
