import { render, staticRenderFns } from "./handleTemplate.vue?vue&type=template&id=b04b3752&scoped=true"
import script from "./handleTemplate.vue?vue&type=script&lang=js"
export * from "./handleTemplate.vue?vue&type=script&lang=js"
import style0 from "./handleTemplate.vue?vue&type=style&index=0&id=b04b3752&prod&lang=scss"
import style1 from "./handleTemplate.vue?vue&type=style&index=1&id=b04b3752&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b04b3752",
  null
  
)

export default component.exports