<template>
  <div>
    <el-row class="row-item">
      <el-col :span="12">
        <div :class="['img-box', item.isBig ? 'img-big' : 'img-small']">
          <img v-show="item.isBig" :src="item.imgSrcBig" />
          <img v-show="!item.isBig" :src="item.imgSrcSmall" />
        </div>
      </el-col>
      <el-col :span="12" class="edit-box">
        <div class="edit-item">
          显示：
          <el-radio-group v-model="item.isShow">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <div class="edit-item">
          LOGO:
          <el-radio-group v-model="item.isBig">
            <el-radio :label="true">大</el-radio>
            <el-radio :label="false">小</el-radio>
          </el-radio-group>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'modlue0',
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.row-item {
  border: 1px dashed #cccccc;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
}
.img-box {
  height: 100%;
  &.img-small {
    img {
      width: 43px;
      height: 43px;
      margin-top: 20px;
      margin-left: 20px;
    }
  }
  &.img-big {
    img {
      width: 100%;
      height: 100%;
      vertical-align: bottom;
    }
  }
}
.edit-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.edit-item {
  text-align: left;
  padding-left: 40px;
  margin-bottom: 10px;
}
</style>
