<!-- previousCatalog -->
<template>
  <div class="previousCatalog">
    <el-form ref="ruleForm" :model="ruleForm" label-width="124px">
      <div class="part">
        <div class="left-part">
          <img src="../../../../assets/img/previousCatalog/previousCatalog.png" alt="" />
          <img src="../../../../assets/img/previousCatalog/morePreviousCatalog.png" alt="" />
        </div>
        <div class="right-part template-list-box">
          <el-form-item label="往期目录模板：" v-if="templateData.length > 0" prop="pastTemplate" class="template-list">
            <el-carousel ref="carousel" :autoplay="false" :initial-index="initialIndex" :loop="false" arrow="hover" indicator-position="none" height="205px" class="cars">
              <el-carousel-item v-for="(item, index) in Math.ceil(templateData.length / 3)" :key="index">
                <div class="cars_div">
                  <dl v-for="radioItem in templateData.slice((item - 1) * 3, item * 3)" :key="radioItem.id">
                    <dt class="clearfix">
                      <div class="img-container">
                        <el-image :src="`${GetPictureStream}?pictureId=${radioItem.coverId}&type=small`" class="template-item-img" alt="" />
                      </div>
                    </dt>
                    <dd>
                      <el-radio v-model="ruleForm.pastTemplate" :label="radioItem.id">{{ radioItem.name }}</el-radio>
                    </dd>
                  </dl>
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-form-item>
          <el-form-item label="查看更多背景色：" size="zdy4" prop="pastMoreColor">
            <el-input v-model="ruleForm.pastMoreColor" placeholder="示例：linear-gradient(90deg, #0089cf, #52a174)或#333333或red" style="line-height: 78px"></el-input>
          </el-form-item>
          <el-form-item label="是大logo：" size="zdy4" prop="isBigLogo" class="isBigLogo">
            <el-radio-group v-model="ruleForm.isBigLogo">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="banner图：" size="zdy4" prop="bannerId">
            <div class="upload-img">
              <img :src="morePreviousCatalogBannerSrc" alt />
              <el-upload
                class="banner-cover upload-demo"
                :action="UploadPictureStream"
                name="file"
                :show-file-list="false"
                accept=".png,.jpg,.jpeg"
                :auto-upload="true"
                :on-success="handleBannerSuccess"
              >
                <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
              </el-upload>
            </div>
          </el-form-item>
          <div slot="tip" class="el-upload__tip">
            格式支持：jpg、png、jpeg
            <span class="betterSize">尺寸推荐：812*100</span>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream, GetPicturePath } from '@/api/configManage'
import { GetTemplateList } from '@/api/selfPush'
export default {
  name: 'previousCatalog',
  data() {
    return {
      morImg: require('@/assets/img/img_default.png'),
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      GetPicturePath: GetPicturePath,
      templateData: [],
      pastMoreColor: '',
      initialIndex: 0
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    'ruleForm.pastMoreColor'() {
      this.ruleForm.pastMoreColor = this.ruleForm.pastMoreColor ? this.ruleForm.pastMoreColor : 'linear-gradient(90deg, #0089cf, #52a174)'
    },
    'ruleForm.pastTemplate'(n) {
      if (n) {
        this.computedInitialIndex()
      }
    }
  },
  components: {},
  computed: {
    previousCatalogBgSrc() {
      return this.previousCatalogBgCover ? this.previousCatalogBgCover : this.ruleForm.pastMoreCoverId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.pastMoreCoverId}` : this.morImg
    },
    morePreviousCatalogBannerSrc() {
      return this.morePreviousCatalogBannerCover ? this.morePreviousCatalogBannerCover : this.ruleForm.bannerId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.bannerId}` : this.morImg
    }
  },
  mounted() {
    this.getTemplateData()
    this.ruleForm.pastMoreColor = this.ruleForm.pastMoreColor ? this.ruleForm.pastMoreColor : 'linear-gradient(90deg, #0089cf, #52a174)'
  },
  methods: {
    // 成功上传往期目录点击查看更多背景图
    handleBgSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.previousCatalogBgCover = URL.createObjectURL(file.raw)
      this.ruleForm.pastMoreCoverId = ID
    },
    // 成功上传更多往期目录banner图
    handleBannerSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.morePreviousCatalogBannerCover = URL.createObjectURL(file.raw)
      this.ruleForm.bannerId = ID
    },
    // 获取模版列表
    getTemplateData() {
      this.loading = true
      let params = {
        templateType: 1
      }
      GetTemplateList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.templateData = data.content
          this.$nextTick(() => {
            if (this.templateData.length > 0) {
              this.computedInitialIndex()
            }
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
        this.loading = false
      })
    },
    computedInitialIndex() {
      this.templateData.map((item, index) => {
        if (this.ruleForm.pastTemplate === item.id) {
          this.initialIndex = index
        }
      })
      this.$refs.carousel.setActiveItem(this.initialIndex)
    }
  }
}
</script>
<style lang="scss">
.previousCatalog {
  .template-list {
    .el-form-item__content {
      display: flex;
      overflow: auto;
      padding: 20px 30px;
      border: 1px solid #ddd;
      text-align: center;
    }
    .template-item-img {
      img {
        object-fit: contain;
      }
    }
    .el-radio__label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .el-upload__tip {
    margin: 0 0 20px 124px !important;
  }
  .cars_div {
    dd {
      label {
        display: inline;
      }
    }
  }
  .isBigLogo {
    label {
      line-height: 78px;
    }
  }
}
</style>
<style lang="scss" scoped>
.previousCatalog {
  .upload-img,
  .upload-demo,
  .bg-cover {
    width: 100% !important;
    height: 70px !important;
  }
  .left-part {
    height: 525px;
    img {
      height: 100%;
      object-fit: contain;
      width: 50% !important;
    }
  }
  .cars {
    width: 100%;
    .cars_div {
      display: flex;
      dl {
        cursor: pointer;
        margin-right: 36px;
      }
      dd {
        overflow: hidden;
        width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
      .template-item-img {
        width: 140px;
        height: 180px;
        display: block;
      }
    }
  }
}
</style>
