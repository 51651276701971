<template>
  <div>
    <el-row class="row-item">
      <el-col :span="12">
        <div class="img-box">
          <img :src="item.imgSrc" alt="" />
        </div>
      </el-col>
      <el-col :span="12" class="edit-box">
        <div class="edit-item">
          显示：
          <el-radio-group v-model="item.isShow" :disabled="item.disabled">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <div class="edit-item" v-if="showType">
          模板类型：
          <el-radio-group v-model="item.type">
            <el-radio :label="1">类型一</el-radio>
            <el-radio :label="2">类型二</el-radio>
          </el-radio-group>
        </div>
        <div class="edit-item" v-if="showAbout && item.type != 2">
          "关于"显示：
          <el-radio-group v-model="item.showMagAbout" :disabled="item.disabled">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'modlue6',
  props: {
    item: {
      type: Object
    },
    showAbout: {
      type: Boolean,
      default: false
    },
    showType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.row-item {
  border: 1px dashed #cccccc;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
}
.img-box {
  img {
    width: 100%;
  }
}
.edit-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.edit-item {
  text-align: left;
  padding-left: 40px;
  margin-bottom: 10px;
}
</style>
