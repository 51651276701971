<!-- hnclTemplate -->
<template>
  <div class="hnclTemplate">
    <!-- LOGO -->
    <module0 :item="mouldConfig.modlue0"></module0>
    <!-- 期刊顶部 -->
    <module9 :item="mouldConfig.modlue9"></module9>
    <!-- 带图片目录列表 -->
    <module3 :item="mouldConfig.modlue3" :showNumber="false" :showColumn="false"></module3>
    <!-- 含能材料每期目录英文介绍 -->
    <module10 :item="mouldConfig.modlue10"></module10>
    <!-- 含能材料过刊浏览 -->
    <module11 :item="mouldConfig.modlue11"></module11>
    <!-- 底部二维码 -->
    <module7 :item="mouldConfig.modlue7"></module7>
  </div>
</template>

<script>
import module0 from './template/module0'
import module3 from './template/module3'
import module7 from './template/module7'
import module9 from './template/module9'
import module10 from './template/module10'
import module11 from './template/module11'
export default {
  name: 'hnclTemplate',
  data() {
    return {}
  },
  props: {
    mouldConfig: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    module0,
    module3,
    module7,
    module9,
    module10,
    module11
  },
  computed: {},
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
