<!-- 基础配置类型 包括所有的模板类型配置 -->
<template>
  <div class="commonType">
    <!--
      color:选择配色 logo:微网刊logo设置 subject:顶部介绍封面设置
      mag:期刊介绍封面设置 codes:二维码设置 defalutCover:论文默认封面设置
      weChatShare: 微信分享设置
    -->
    <el-form ref="ruleForm" :model="ruleForm" label-width="110px">
      <color :ruleForm="ruleForm"></color>
      <logo :ruleForm="ruleForm" :logoImg="logoImg" showResetBtn></logo>
      <!-- <subject :ruleForm="ruleForm" :subjectImg="subjectImg"></subject> -->
      <!-- <mag isCommonType :ruleForm="ruleForm" :magImg="magImg" :maxlength="12"></mag> -->
      <!-- <skCover :ruleForm="ruleForm"></skCover> -->
      <codes :ruleForm="ruleForm" :codeImg="codeImg"></codes>
      <defalutCover :ruleForm="ruleForm" :defaultCoverImg="defaultCoverImg"></defalutCover>
      <!-- <weChatShare :ruleForm="ruleForm"></weChatShare> -->
    </el-form>
  </div>
</template>

<script>
import color from './module/color'
import defalutCover from './module/defalutCover'
import logo from './module/logo'
import mag from './module/mag'
import skCover from './module/skCover'
import codes from './module/codes'
import subject from './module/subject'
import weChatShare from './module/weChatShare.vue'
export default {
  name: 'commonType',
  data() {
    return {
      codeImg: require('@/assets/img/template_img/zk/module7.png'),
      defaultCoverImg: require('@/assets/img/template_img/zk/module3.png'),
      logoImg: require('@/assets/img/template_img/zk/module0.png'),
      magImg: require('@/assets/img/template_img/zk/module6.png'),
      subjectImg: require('@/assets/img/template_img/zk/module9.png')
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    color,
    defalutCover,
    logo,
    // mag,
    // skCover,
    codes
    // subject,
    // weChatShare
  },
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {},
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
