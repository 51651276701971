<!-- firstType -->
<template>
  <div class="firstType">
    <div class="part fl">
      <img :src="topicCatalog" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'firstType',
  data() {
    return {
      topicCatalog: require('@/assets/img/topicCatalog/topicCatalog.png')
    }
  },
  props: {},
  components: {},
  computed: {},
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
