<!-- others -->
<template>
  <div class="others">
    <el-form ref="ruleForm" :model="ruleForm" label-width="160px">
      <el-form-item label="版权信息：" prop="copyright">
        <el-input v-model="ruleForm.copyright" type="text" />
      </el-form-item>
      <el-form-item class="type-arr" label="文章地址类型优先级：" prop="">
        <el-radio-group v-model="radio" v-for="(item, index) in typeArr" :key="index">
          <el-radio :label="item.value">{{ item.label }}</el-radio>
          <span class="dyIcon" v-if="index !== typeArr.length - 1">></span>
        </el-radio-group>
        <div class="btn">
          <el-button v-if="showLeft" size="small" round icon="el-icon-back" @click="toLeft">左移</el-button>
          <el-button v-if="showRight" size="small" round icon="el-icon-right" @click="toRight">右移</el-button>
        </div>
      </el-form-item>
      <!-- <div class="weChat-share part">
        <div class="left">
          <el-form-item label="微信分享图片：" size="zdy4" prop="weChatShareCoverId">
            <div class="upload-img">
              <img :src="weChatShareCoverSrc" alt />
              <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleWeChatShareCover">
                <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
              </el-upload>
            </div>
          </el-form-item>
        </div>
        <div class="right">
          <el-form-item label="分享标题" size="zdy6" prop="weChatShareTitle">
            <el-input v-model="ruleForm.weChatShareTitle" type="text" />
          </el-form-item>
          <el-form-item label="分享内容" size="zdy1" prop="weChatShareContent">
            <el-input autosize v-model="ruleForm.weChatShareContent" type="textarea" />
          </el-form-item>
        </div>
      </div> -->
    </el-form>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream, GetPicturePath } from '@/api/configManage'
export default {
  name: 'others',
  data() {
    return {
      morImg: require('@/assets/img/img_default.png'),
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      GetPicturePath: GetPicturePath,
      weChatShareCover: '',
      typeArr: [
        {
          label: '微站',
          value: 'pubUrl'
        },
        {
          label: '全文',
          value: 'htmlUrl'
        },
        {
          label: '摘要',
          value: 'abstractUrl'
        },
        {
          label: '微信',
          value: 'wechatUrl'
        },
        {
          label: '知网',
          value: 'url'
        }
      ],
      radio: '',
      showLeft: false,
      showRight: false,
      chooseIndex: 0
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  computed: {
    //微信分享图片
    weChatShareCoverSrc() {
      return this.weChatShareCover ? this.weChatShareCover : this.ruleForm.weChatShareCoverId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.weChatShareCoverId}` : this.morImg
    }
  },
  watch: {
    radio() {
      this.computedShowLeftRight()
    },
    ruleForm(n) {
      if (!n.copyright) {
        this.ruleForm.copyright = '本系统由北京北大方正电子有限公司提供技术支持'
      }
    },
    'ruleForm.htmlOrder'(n) {
      if (this.ruleForm.htmlOrder && this.typeArr.length <= 5) {
        let arr = this.ruleForm.htmlOrder.split(',')
        let newArr = []
        if (arr.indexOf('pubUrl') === -1) {
          let obj = { label: '微站', value: 'pubUrl' }
          newArr.push(obj)
          this.ruleForm.htmlOrder = 'pubUrl,' + this.ruleForm.htmlOrder
        }
        arr.map((item, index) => {
          this.typeArr.map((it, inx) => {
            if (item === it.value) {
              newArr.push(it)
            }
          })
        })
        this.typeArr = newArr
      }
    }
  },
  mounted() {},
  methods: {
    // 成功上传微信分享图片
    handleWeChatShareCover(res, file) {
      const { data } = res
      const { ID } = data
      this.weChatShareCover = URL.createObjectURL(file.raw)
      this.ruleForm.weChatShareCoverId = ID
    },
    // 文章优先级左移
    toLeft() {
      this.typeArr.map((item, index) => {
        if (item.value === this.radio) {
          this.chooseIndex = index
        }
      })
      let newArr = this.swapItems(this.typeArr, this.chooseIndex, this.chooseIndex - 1)
      this.typeArr = newArr
      this.computedShowLeftRight()
    },
    // 文章优先级右移
    toRight() {
      this.typeArr.map((item, index) => {
        if (item.value === this.radio) {
          this.chooseIndex = index
        }
      })
      let newArr = this.swapItems(this.typeArr, this.chooseIndex, this.chooseIndex + 1)
      this.typeArr = newArr
      this.computedShowLeftRight()
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    computedShowLeftRight() {
      this.typeArr.map((item, index) => {
        if (item.value === this.radio) {
          this.chooseIndex = index
        }
      })
      this.showLeft = this.chooseIndex !== 0
      this.showRight = this.chooseIndex !== this.typeArr.length - 1
      this.ruleForm.htmlOrder = ''
      this.typeArr.map((item, index) => {
        this.ruleForm.htmlOrder = index === this.typeArr.length - 1 ? this.ruleForm.htmlOrder + item.value : this.ruleForm.htmlOrder + item.value + ','
      })
    }
  }
}
</script>
<style lang="scss">
.others {
  .type-arr {
    .el-radio {
      margin-right: 10px;
    }
    .el-form-item__content {
      display: flex;
      align-items: center;
      font-size: 14px;
      height: 32px;
      .dyIcon {
        margin-right: 10px;
        font-size: 18px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.others {
  padding: 20px 150px;
  .type-arr {
    .btn {
      margin-left: 40px;
    }
  }
  .weChat-share {
    display: flex;
    .left {
      width: 35%;
    }
    .right {
      width: 60%;
    }
    .upload-img {
      width: 150px;
      height: 150px;
    }
    .upload-demo {
      width: 150px;
      height: 150px;
    }
  }
}
</style>
