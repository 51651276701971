<!-- handleCatalog -->
<template>
  <div class="w center_all handleCatalog">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>微信传播</li>
        <li>-></li>
        <li class="web-template" @click="goToWebTemplate">通用组件模板</li>
        <li>-></li>
        <li>{{ templateTitle }}微网刊目录模版</li>
      </ul>
      <div class="edits">
        <el-form ref="handleTemplate" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
          <el-form-item label="模版名称:" prop="templateName" placeholder="请输入模版名称">
            <el-input v-model="ruleForm.templateName" type="text" />
          </el-form-item>
          <!-- <el-form-item label="模版类别:" prop="templateType" style="text-align:left">
            <el-select v-model="currentTemplateType" placeholder="请选择模版类别">
              <el-option v-for="item in templateTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="配置模版组件:">
            <component :is="currentTemplateComList[currentTemplateType]" :mouldConfig="mouldConfig"> </component>
          </el-form-item>
        </el-form>
      </div>
      <div class="btns_form btns" style="text-align: center">
        <el-button type="primary" @click="submitTemplateInfo">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { UploadPictureStream, getTemplate, submitWorktemplate } from '@/api/configManage'
import { GetConfig } from '@/api/weChatPush'
import previousCatalog from './components/catalogTemplate/previousCatalog/firstType'
import topicCatalog from './components/catalogTemplate/topicCatalog/firstType'
import { mapGetters } from 'vuex'
export default {
  name: 'handleCatalog',
  data() {
    return {
      morImg: require('@/assets/img/img_default.png'),
      UploadPictureStream: UploadPictureStream,
      ruleForm: {
        templateName: '',
        templateType: 1
      },
      rules: {
        templateName: [{ required: true, message: '请输入模版名称', trigger: 'blur' }]
      },
      currentTemplateType: 1,
      templateTypeOptions: [
        { label: '往期目录', value: 1 },
        { label: '专题目录', value: 2 }
      ],
      currentTemplateComList: {
        1: previousCatalog, //往期目录
        2: topicCatalog // 专题目录
      },
      mouldConfig: {},
      type: 1 // 1自科类，2社科类
    }
  },
  props: {},
  components: {
    previousCatalog,
    topicCatalog
  },
  computed: {
    ...mapGetters({
      platMagId: 'platMagId',
      platTenantId: 'platTenantId',
      magId: 'magId'
    }),
    templateTitle() {
      return this.$route.params.templateState == 'create' ? '创建' : '编辑'
    }
  },
  created() {
    this.getConfig()
    if (this.$route.params.templateState == 'edit' && this.$route.params.templateId) {
      this.getTemplateDetail()
    }
  },
  methods: {
    goToWebTemplate() {
      this.$router.push({
        name: 'webJournalTemplate',
        params: { activeName: 'second' }
      })
    },
    // 获取模版详情
    getTemplateDetail() {
      const params = {
        id: this.$route.params.templateId
      }
      getTemplate(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.ruleForm.templateName = res.data.data.name || ''
          this.ruleForm.templateType = res.data.data.templateType || 1
          this.mouldConfig = JSON.parse(res.data.data.mouldConfig)
        }
      })
    },
    // 获取基础配置
    getConfig() {
      let params = {
        tenantId: this.platTenantId,
        publicationId: this.platMagId,
        magId: this.magId
      }
      GetConfig(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          this.type = data.type
        }
      })
    },
    // 提交保存模版配置
    submitTemplateInfo() {
      let params = {}
      if (this.$route.params.templateState == 'create') {
        params = {
          name: this.ruleForm.templateName,
          templateType: this.ruleForm.templateType,
          type: this.type,
          mouldConfig: JSON.stringify(this.mouldConfig)
        }
      } else {
        params = {
          name: this.ruleForm.templateName,
          templateType: this.ruleForm.templateType,
          type: this.type,
          mouldConfig: JSON.stringify(this.mouldConfig),
          id: this.$route.params.templateId
        }
      }
      if (!this.ruleForm.templateName) {
        this.$message({
          message: '请填写模版名称',
          type: 'error'
        })
      }
      this.$refs.handleTemplate.validate((valid) => {
        if (valid) {
          submitWorktemplate(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                message: this.$route.params.templateState == 'create' ? '创建成功' : '编辑成功',
                type: 'success'
              })
              this.$router.push({
                name: 'webJournalTemplate',
                params: { activeName: 'second' }
              })
            } else {
              this.$message({
                message: res.data.message,
                type: 'error'
              })
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.handleCatalog {
  .web-template {
    cursor: pointer;
  }
  .edits {
    background: #f5f7fd;
    padding: 40px 160px 40px;
    position: relative;
  }
}
</style>
