<!-- skCover 社科的左侧封面 -->
<template>
  <div class="skCover">
    <el-form ref="ruleForm" :model="ruleForm" label-width="124px">
      <div class="part">
        <div class="left-part">
          <img :src="skCoverImg" alt="" />
        </div>
        <div class="right-part">
          <div class="flex-content">
            <div class="left">
              <el-form-item label="轮播图左侧图片：" size="zdy4" prop="deRotationCoverId">
                <div class="mag-cover upload-img">
                  <img :src="deRotationCoverSrc" alt />
                  <el-upload
                    class="upload-demo"
                    :action="UploadPictureStream"
                    name="file"
                    :show-file-list="false"
                    accept=".png,.jpg,.jpeg"
                    :auto-upload="true"
                    :on-success="handleDeRotationCoverSuccess"
                  >
                    <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                  </el-upload>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="el-upload__tip">格式支持：jpg、png、jpeg<span class="betterSize">尺寸推荐：156*231</span></div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream } from '@/api/configManage'
export default {
  name: 'skCover',
  data() {
    return {
      morImg: require('@/assets/img/img_default.png'),
      skCoverImg: require('@/assets/img/template_img/sk/module1.png'),
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      deRotationCover: ''
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {
    // 轮播图左侧图片图片
    deRotationCoverSrc() {
      return this.deRotationCover ? this.deRotationCover : this.ruleForm.deRotationCoverId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.deRotationCoverId}` : this.morImg
    }
  },
  created() {},
  methods: {
    handleDeRotationCoverSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.deRotationCover = URL.createObjectURL(file.raw)
      this.$set(this.ruleForm, 'deRotationCoverId', ID)
    }
  }
}
</script>

<style lang="scss" scoped>
.skCover {
  .left-part {
    height: 277px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .mag-cover {
    width: 156px;
    height: 231px;
  }
}
</style>
