<template>
  <div>
    <!-- LOGO -->
    <module0 :item="mouldConfig.modlue0"></module0>
    <!-- 期刊顶部 -->
    <module9 :item="mouldConfig.modlue9"></module9>
    <!-- 轮播图 -->
    <module1 :item="mouldConfig.modlue1"></module1>

    <module2 :item="mouldConfig.modlue2"></module2>
    <module3 :item="mouldConfig.modlue3"></module3>
    <module4 :item="mouldConfig.modlue4"></module4>

    <!-- 热门文章 -->
    <module5 :item="mouldConfig.modlue5"></module5>
    <!-- 期刊介绍 -->
    <module6 :item="mouldConfig.modlue6"></module6>
    <!-- 关注我们 -->
    <module7 :item="mouldConfig.modlue7"></module7>
    <!-- 版权声明 -->
    <module8 :item="mouldConfig.modlue8"></module8>
  </div>
</template>

<script>
import module0 from './template/module0'
import module1 from './template/module1'
import module2 from './template/module2'
import module3 from './template/module3'
import module4 from './template/module4'
import module5 from './template/module5'
import module6 from './template/module6'
import module7 from './template/module7'
import module8 from './template/module8'
import module9 from './template/module9'

export default {
  props: {
    mouldConfig: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    module0,
    module1,
    module2,
    module3,
    module4,
    module5,
    module6,
    module7,
    module8,
    module9
  },
  watch: {
    'mouldConfig.modlue4.showNumber'(value) {
      this.handleCom4Number(value)
    },
    'mouldConfig.modlue3.showNumber'(value) {
      this.handleCom3Number(value)
    }
  },
  mounted() {},
  data() {
    return {}
  },
  methods: {
    // 配置模版显示次数
    handleCom3Number(value) {
      if (value == '') {
        return false
      }
      if (value == -1) {
        this.mouldConfig.modlue4.isShow = false
        this.mouldConfig.modlue4.showNumber = ''
        this.mouldConfig.modlue4.disabled = true
        this.mouldConfig.modlue5.isShow = false
        this.mouldConfig.modlue5.showNumber = ''
        this.mouldConfig.modlue5.disabled = true
      } else {
        this.mouldConfig.modlue4.isShow = true
        this.mouldConfig.modlue4.disabled = false
        this.mouldConfig.modlue5.isShow = true
        this.mouldConfig.modlue5.disabled = false
      }
    },
    handleCom4Number(value) {
      if (value == '') {
        return false
      }
      if (value == -1) {
        this.mouldConfig.modlue5.isShow = false
        this.mouldConfig.modlue5.showNumber = ''
        this.mouldConfig.modlue5.disabled = true
      } else {
        this.mouldConfig.modlue5.isShow = true
        this.mouldConfig.modlue5.disabled = false
      }
    }
  }
}
</script>
