<!-- catalog -->
<template>
  <div class="catalog-template web-journal-template">
    <el-row type="flex" justify="space-between">
      <el-col :span="4">
        <el-button type="primary" @click="createNewTemplate">新建模版</el-button>
      </el-col>
      <!-- <el-col :span="6" style="text-align: right">
        模版类别：
        <el-select v-model="currentTemplateType" placeholder="请选择" @change="templateTypeChange">
          <el-option v-for="item in templateTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-col> -->
    </el-row>
    <el-row v-loading="loading" :gutter="10" style="margin-top: 15px">
      <el-col v-for="(item, index) in templateData" :key="item.id" style="max-width: 230px">
        <el-card :body-style="{ padding: '7px' }" class="el-card-item">
          <div class="template-cover-box" @click="templateClick(item, index)">
            <div class="template-cover">
              <img class="cus-img" :class="item.coverId ? '' : 'defaultImg'" :src="item.templateCover" alt />
            </div>
            <el-upload :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" class="upload-demo" :auto-upload="true" :on-success="handleTemplateCoverSuccess">
              <el-button class="postion2" size="small" type="primary">上传图片</el-button>
            </el-upload>
          </div>
          <div style="padding: 5px">
            <p class="template-name">{{ item.name }}</p>
            <div class="card-bottom">
              <span class="template-type">
                <el-tag size="mini" effect="plain" :type="templateTagType(item.type)">{{ formatterTemplateType(item.type) }}</el-tag>
              </span>
              <p class="button-area">
                <el-button type="text" @click="preEditTem(item)">编辑</el-button>
                <el-button type="text" @click="preDeleteTem(item.id)">删除</el-button>
              </p>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <Pagination v-if="templateData.length > 0" :initObj="initObj" @onChangePageData="handleChangePageData" />
    <!-- 删除模版提示 -->
    <el-dialog title="提示" :visible.sync="deleteTemplateVisible" width="30%">
      <span>确定删除该模版吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteTemplateVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteTemplateSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream, GetPicturePath, DeleteConfig } from '@/api/configManage'
import { UpdateTemplateCover } from '@/api/weChatPush'
import { GetTemplateList } from '@/api/selfPush'
import Pagination from '@/components/Pagination'
export default {
  name: 'catalog',
  data() {
    return {
      UploadPictureStream: UploadPictureStream,
      GetPictureStream: GetPictureStream,
      GetPicturePath: GetPicturePath,
      loading: true,
      morImg: require('@/assets/img/img_default.png'),
      defaultImg: require('@/assets/img/default.png'),
      templateData: [],
      initObj: {
        page: 1,
        size: 10
      },
      currentPage: 1,
      clickTemplateItem: '',
      clickTemplateIndex: '',
      deleteTemplateVisible: false,
      currentTemplateId: ''
    }
  },
  props: {},
  components: {
    Pagination
  },
  computed: {},
  created() {
    this.getTemplateData()
  },
  methods: {
    getTemplateData() {
      this.loading = true
      let params = {
        page: this.initObj.page - 1,
        size: this.initObj.size,
        templateType: 1
      }
      GetTemplateList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.templateData = data.content
          this.templateData.map((item, index) => {
            if (item.coverId) {
              this.$set(this.templateData[index], 'templateCover', `${GetPictureStream}?pictureId=${item.coverId}`)
            } else {
              this.$set(this.templateData[index], 'templateCover', this.morImg)
            }
          })
          this.initObj = {
            page: this.currentPage,
            size: data.size,
            total: data.totalElements
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
        this.loading = false
      })
    },
    formatterTemplateType(value) {
      let str = ''
      switch (value) {
        case 1:
          str = '遥感'
          break
        case 2:
          str = '社科'
          break
      }
      return str
    },
    templateTagType(value) {
      let str = ''
      switch (value) {
        case 1:
          str = ''
          break
        case 2:
          str = 'success'
          break
      }
      return str
    },
    // 新建模版
    createNewTemplate() {
      this.$router.push({
        name: 'handleCatalog',
        params: {
          templateState: 'create'
        }
      })
    },
    templateClick(item, index) {
      this.clickTemplateItem = item
      this.clickTemplateIndex = index
    },
    //模板封面上传成功后
    handleTemplateCoverSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.$set(this.templateData[this.clickTemplateIndex], 'templateCover', URL.createObjectURL(file.raw))
      const params = {
        coverId: ID,
        id: this.clickTemplateItem.id
      }
      UpdateTemplateCover(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success('上传成功')
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
        // this.getTemplateData();
      })
    },
    // 页码改变了之后的操作
    handleChangePageData(value) {
      this.initObj.page = value.page
      this.initObj.size = value.size
      this.currentPage = value.page
      this.getTemplateData()
    },
    // 编辑模版
    preEditTem(item) {
      this.$router.push({
        name: 'handleCatalog',
        params: {
          templateState: 'edit',
          templateId: item.id
        }
      })
    },
    // 删除模版
    preDeleteTem(id) {
      this.currentTemplateId = id
      this.deleteTemplateVisible = true
    },
    //确定删除
    deleteTemplateSure() {
      const params = {
        ids: this.currentTemplateId
      }
      DeleteConfig(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success(res.data.message)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
        // 刷新列表
        this.getTemplateData()
        this.deleteTemplateVisible = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.catalog-template {
  min-height: 200px;
}
</style>
